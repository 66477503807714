import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
const NavbarComponent = () => {
  return (
    <Navbar id = "navbar-background" expand="lg">
      <Container className = "cta-navbar-hero">
        <Navbar.Brand href="https://www.styleesports.com"><h2>Home</h2></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="https://styleesports.com/TeamPage/"><h2>TeamPage</h2></Nav.Link>
                
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavbarComponent;
